<template>
  <div>
    <v-toolbar absolute fixed dense style="left: 0; width: 100%;" color="primary">
      <v-row dense align="center">
        <v-col cols="10" class="white--text">
          <v-icon color="white" class="mr-2">mdi-account</v-icon>
          <span class="text-h5">{{ nmAcao }} cliente</span>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn icon color="white" @click="abreFechaJanela(false)">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <!-- FORMULÁRIO DE CADASTRO -->
    <v-form v-model="valid" ref="form" style="padding-top: 60px">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">DADOS PESSOAIS</div>
        </v-col>
        <v-col cols="12" md="9">
          <v-text-field
            outlined dense
            autofocus
            hide-details="auto"
            label="Nome"
            color="primary"
            ref="nome"
            v-model="data.Nome"
            :rules="validations.nome"
            prepend-inner-icon="mdi-face-man" />
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            outlined dense
            hide-details="auto"
            color="primary"
            label="CPF / CNPJ"
            type="tel"
            v-model="data.Documento"
            :rules="validations.cpf"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            prepend-inner-icon="mdi-card-account-details-outline" />
        </v-col>

        <v-col cols="12" class="mt-5">
          <div class="text-h6">ENDEREÇO</div>
        </v-col>
        <v-col cols="12" sm="3" md="2">
          <v-text-field
            outlined dense
            hide-details="auto"
            label="CEP"
            color="primary"
            type="tel"
            v-model="data.Cep"
            v-mask="'#####-###'"
            @keyup="buscaCEP"
            prepend-inner-icon="mdi-map-marker" />
        </v-col>
        <v-col cols="8" sm="7" md="8">
          <v-text-field
            outlined dense
            hide-details="auto"
            label="Rua, Avenida..."
            color="primary"
            v-model="data.Endereco" />
        </v-col>
        <v-col cols="4" sm="2">
          <v-text-field
            outlined dense
            hide-details="auto"
            class="numero"
            label="Número"
            color="primary"
            type="tel"
            v-model="data.Numero"
            v-mask="'#####'" />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            outlined dense
            hide-details="auto"
            label="Bairro"
            color="primary"
            v-model="data.Bairro" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined dense
            hide-details="auto"
            label="Complemento"
            color="primary"
            v-model="data.Complemento" />
        </v-col>

        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined dense
            hide-details="auto"
            return-object
            color="primary"
            label="Estado"
            v-model="cmbEstado"
            :items="tblEstados"
            item-text="Estado"
            item-value="Id"
            @change="pegaCidades(func.vldCombo($event))">
            <template v-slot:item="{ item }">
              <b>{{ item.Sigla }}</b>&nbsp;-&nbsp;{{ item.Estado.split('-')[1] }}
            </template>
            <template v-slot:selection="{ item }">
              <b>{{ item.Sigla }}</b>&nbsp;-&nbsp;{{ item.Estado.split('-')[1] }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            outlined dense
            hide-details="auto"
            return-object
            color="primary"
            label="Cidade"
            v-model="cmbCidade"
            :items="tblCidades"
            item-text="Nome"
            item-value="Id"
            :loading="loadCidade" />
        </v-col>

        <v-col cols="12" class="mt-5">
          <div class="text-h6">CONTATOS</div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined dense
            hide-details="auto"
            label="E-Mail"
            color="primary"
            v-model="data.Email"
            :rules="validations.email"
            prepend-inner-icon="mdi-at" />
        </v-col>
        <v-col cols="6" sm="4" md="3">
          <v-text-field
            outlined dense
            hide-details="auto"
            return-masked-value
            label="WhatsApp"
            color="primary"
            type="tel"
            v-model="data.Fone1"
            :rules="validations.fone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            prepend-inner-icon="mdi-whatsapp" />
        </v-col>
        <v-col cols="6" sm="4" md="3">
          <v-text-field
            outlined dense
            hide-details="auto"
            return-masked-value
            label="Telefone"
            color="primary"
            type="tel"
            v-model="data.Fone2"
            :rules="validations.fone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            prepend-inner-icon="mdi-phone" />
        </v-col>

        <v-col cols="12" class="text-center mt-3">
          <v-btn color="success" class="mx-1 mt-2" :disabled="!valid" @click="submit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            SALVAR
          </v-btn>
          <v-btn dark color="error" class="mx-1 mt-2" @click="limpaCampos()">
            <v-icon class="mr-2">mdi-close</v-icon>
            LIMPAR
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-overlay v-model="carregando">
      <v-card color="primary" dark width="150">
        <v-card-text class="text-center">
          Aguarde...
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-overlay>
  </div>
</template>
 
<script>
import func from "../../utils/funcoes";
import { mask } from "vue-the-mask";
import { eventHub } from "../../utils/eventHub";

export default {
  props: {
    idCliente: {
      type: Number,
      default: null
    }
  },
  directives: { mask },
  data () {
    return {
      func,
      valid: false,
      carregando: false,
      data: {},
      acao: "ins",

      cliques: 0,

      tblEstados: [],
      tblCidades: [],

      cmbEstado: {},
      cmbCidade: {},

      loadCidade: false,

      validations: {
        nome: [
          (v) => !!v || "Nome é obrigatório",
          (v) => (v && func.vldRazaoSocial(v)) || "Nome inválido ou faltando dados",
        ],
        cpf: [
          (v) => !!v || "CPF / CNPJ é obrigatório",
          (v) => (v && func.vldCpfCnpj(v)) || "CPF / CNPJ inválido",
        ],
        email: [(v) => func.empty(v) || func.vldMail(v) || "E-Mail inválido"],
        fone: [(v) => func.empty(v) || func.vldFone(v) || "Número inválido"],
      },
    };
  },
  async mounted () {
    this.carregando = true;
    let dados = await Promise.all([this.pegaEstados()]);
    this.tblEstados = dados[0];
    this.carregando = false;

    if (this.idCliente) this.pegaDados(this.idCliente);
  },
  computed: {
    nmAcao () {
      return this.acao == "ins" ? "Novo" : "Alterar";
    },
    usuario () {
      return this.$store.state.auth.userData;
    },
  },
  watch: {
    idCliente (vlr) {
      let status = true;

      if (func.empty(vlr)) {
        this.limpaCampos();
        status = false;
      } else {
        this.pegaDados(vlr);
      }

      this.abreFechaJanela(status);
    }
  },
  methods: {
    abreFechaJanela (data) {
      if (data == false) this.limpaCampos();
      eventHub.$emit("form-cliente", data);
    },
    buscaCEP () {
      let cep = this.data.Cep;

      if (cep.length == 9) {
        func.buscaCEP(cep, (res) => {
          this.data.Endereco = res.logradouro;
          this.data.Bairro = res.bairro;
          this.cmbEstado = this.tblEstados.find((e) => e.Sigla == res.estado);
          this.pegaCidades(this.cmbEstado.Id, null, res.cidade_info.codigo_ibge);
        });
      } else {
        this.data.Endereco = "";
        this.data.Bairro = "";
        this.cmbEstado = null;
        this.cmbCidade = null;
      }
    },
    async pegaEstados () {
      let url = `/api/v1/admin/estados/combo`;

      return window.axios
        .get(url)
        .then((res) => {
          if (!func.empty(res.data.result.error)) {
            func.notifica(res.data.result.message, "top", "red", 5000);
            return [];
          } else return res.data.result.map(e => {
            e.Estado = `${e.Sigla}-${e.Estado}`;
            return e;
          });
        })
        .catch((error) => {
          console.log("ERROR:", error);
          return [];
        });
    },
    pegaCidades (estado, id = null, ibge = null) {
      if (func.empty(estado)) return;

      let url = `/api/v1/admin/cidades/combo?estado=${estado}`;
      this.tblCidades = [];
      this.loadCidade = true;

      window.axios.get(url)
        .then((res) => {
          if (!func.empty(res.data.result.error))
            func.notifica(res.data.result.message, "top", "red", 5000);
          else {
            this.tblCidades = res.data.result;
            if (id)
              this.cmbCidade = this.tblCidades.find((e) => e.Id == id);
            else if (ibge)
              this.cmbCidade = this.tblCidades.find((e) => e.CodIbge == ibge);
          }
        })
        .catch((error) => {
          console.log("ERROR:", error);
        })
        .finally(() => {
          this.loadCidade = false;
        });
    },
    async pegaDados (id) {
      if (func.empty(id)) return;

      let url = `/api/v1/admin/clientes?id=${id}`;

      this.carregando = true;

      return window.axios.get(url)
        .then((res) => {
          if (res.data.result.error)
            func.notifica(res.data.result.message, "top", "red", 5000);
          else
            this.compoeCampos(res.data.result);

          return true;
        })
        .catch((error) => {
          console.log("ERROR:", error);
          return false;
        })
        .finally(() => {
          this.carregando = false;
        });
    },
    compoeCampos (dados) {
      this.acao = "upd";
      this.data = JSON.parse(JSON.stringify(dados));

      this.data.Cep = dados.Cep.padStart(8, "0");
      this.cmbEstado = this.tblEstados.find((e) => e.Id == this.data.IdEstado);
      this.pegaCidades(dados.IdEstado, dados.IdCidade);

      delete this.data.Status;
    },
    submit () {
      this.cliques++;
      if (this.cliques > 1) return;
      setTimeout(() => {
        this.cliques = 0;
      }, 1000);

      let dados = JSON.parse(JSON.stringify(this.data));
      dados.IdEstado = func.vldCombo(this.cmbEstado);
      dados.IdCidade = func.vldCombo(this.cmbCidade);

      this.carregando = true;

      let url = `/api/v1/admin/clientes`;
      if (this.acao == "ins") {
        window.axios.post(url, dados)
          .then((res) => {
            if (!func.empty(res.data.error)) {
              func.notifica(res.data.message, "top", "red", 5000);
            } else {
              func.notifica("Cliente inserido com sucesso!!!", "top", "green");
              this.$emit('create', res.data);
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            this.carregando = false;
          });
      } else if (this.acao == "upd") {
        window.axios.post(`${url}/${dados.Id}`, dados)
          .then((res) => {
            if (!func.empty(res.data.error)) {
              func.notifica(res.data.message, "top", "red", 5000);
            } else {
              func.notifica("Cliente alterado com sucesso!!!", "top", "green");
              this.$emit('update', res.data);
            }
          })
          .catch((error) => {
            func.notifica(error, "top", "red", 5000);
          })
          .finally(() => {
            this.carregando = false;
          });
      }
    },
    limpaCampos () {
      this.acao = "ins";
      this.$refs.form.reset();
      this.$refs.nome.focus();
      this.data = {};
    },
  },
};
</script>